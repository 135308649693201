.container {
	/* cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png')
			39 39,
		auto; */
}

.deck {
	position: absolute;
	width: 300px;
	height: 200px;
	will-change: transform;
	display: flex;
	align-items: center;
	justify-content: center;
	touch-action: none;
}

.deck > div {
	touch-action: none;
	background-color: white;
	background-size: 50%;
	/* background-size: auto 85%; */
	background-repeat: no-repeat;
	background-position: center center;
	width: 25vh;
	max-width: 300px;
	height: 45vh;
	max-height: 570px;
	will-change: transform;
	border-radius: 10px;
	box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
		0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

.section button {
	will-change: filter;
	transition: filter 300ms;
}
.section button:hover {
	filter: drop-shadow(0 0 4em #fff);
}
