@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

body {
	font-family: system-ui, sans-serif;
	min-height: 100vh;
	margin: 0;
}

*,
*:after,
*:before {
	box-sizing: border-box;
}

.flex {
	display: flex;
	align-items: center;
}

.flex.fill {
	height: 100%;
}

.flex.center {
	justify-content: center;
}
